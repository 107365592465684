<template>
  <v-app class="app-bg">
    <v-main>
      <WaterMark />
      <router-view></router-view>
      <DisabledMedicalAddon></DisabledMedicalAddon>
    </v-main>
  </v-app>
</template>

<script>
import store from "@/store/index";
import ResourcesService from "@/services/resources.service";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    EnableMedicalAddonView: () => import("./EnableMedicalAddonView.vue"),
    DisabledMedicalAddon: () =>
      import("@/components/popups/DisabledMedicalAddon"),
    WaterMark: () => import("@/components/WaterMark"),
  },
  computed: {
    ...mapGetters("ui", ["enabledMedicalAddon"]),
  },
  methods: {
    ...mapActions({
      updateEnabledMedicalAddon: "ui/updateEnabledMedicalAddon",
      updateCanUsePatientCards: "ui/updateCanUsePatientCards",
      updateAccessToOtherCards: "ui/updateAccessToOtherCards",
    }),
    handleLogout(event) {
      if (
        event.storageArea === sessionStorage &&
        event.key === "access_token" &&
        event.newValue !== event.oldValue &&
        event.oldValue
      ) {
        store.dispatch("ui/reset");
        store.dispatch("autosave/reset");
        this.$router
          .push({
            name: "/",
            query: { redirect: this.$route.fullPath },
          })
          .catch((err) => console.log(err));
      }
    },
  },
  mounted() {
    window.addEventListener("storage", this.handleLogout);
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.handleLogout);
  },
  created() {
    localStorage.removeItem("BACK");
    ResourcesService.check().then((reply) => {
      // Enable it on Booksy Med side:
      this.updateEnabledMedicalAddon(reply.medical_addon_enabled);
      // Set if user can use patient cards:
      this.updateCanUsePatientCards(reply.can_use_patient_cards);
      // Set if user can access other patient cards:
      this.updateAccessToOtherCards(reply.access_to_other_cards);
    });
  },
};
</script>